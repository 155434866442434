<template>
  <div class="support-card">
    <h3 class="text-h5 white--text mb-6">Contacto Directo</h3>
    <p class="text-body-2 grey--text text--lighten-1 mb-6">
      Envíanos un mensaje y te responderemos en breve
    </p>

    <v-form @submit.prevent="handleMessageSubmit" v-model="valid">
      <v-text-field
        v-model="messageForm.name"
        label="Nombre"
        :rules="nameRules"
        outlined
        dense
        dark
        class="mb-3 contact-input"
        hide-details="auto"
      ></v-text-field>

      <v-text-field
        v-model="messageForm.email"
        label="Correo electrónico"
        :rules="emailRules"
        outlined
        dense
        dark
        class="mb-3 contact-input"
        hide-details="auto"
      ></v-text-field>

      <v-textarea
        v-model="messageForm.message"
        label="Mensaje"
        :rules="messageRules"
        outlined
        dense
        dark
        class="mb-4 contact-input"
        hide-details="auto"
        rows="3"
      ></v-textarea>

      <v-btn
        block
        color="secondary"
        height="44"
        :loading="sending"
        type="submit"
        class="contact-submit-btn"
        :disabled="!valid"
      >
        <v-icon left>mdi-send</v-icon>
        Enviar Mensaje
      </v-btn>
    </v-form>

    <!-- Indicador de Encriptación -->
    <div class="encryption-indicator mt-4 d-flex align-center">
      <v-icon small color="secondary" class="mr-2">mdi-shield-check</v-icon>
      <span class="text-caption grey--text text--lighten-1">
        Mensaje seguro y encriptado
      </span>
    </div>
  </div>
</template>

<style scoped>
.contact-input ::v-deep .v-input__slot {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  transition: all 0.3s ease;
}

.contact-input ::v-deep .v-input__slot:hover {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.contact-input ::v-deep .v-input__slot:focus-within {
  border-color: var(--v-secondary-base) !important;
}

.contact-input ::v-deep .v-label {
  color: rgba(255, 255, 255, 0.7) !important;
}

.contact-submit-btn {
  text-transform: none;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.contact-submit-btn:not(:disabled):hover {
  transform: translateY(-2px);
}

.encryption-indicator {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 16px;
}

@media (max-width: 960px) {
  .support-card {
    margin-top: 24px;
  }
}
</style>

<script>
export default {
  data: () => ({
    valid: false,
    sending: false,
    messageForm: {
      name: "",
      email: "",
      message: "",
    },
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => v.length <= 50 || "El nombre debe tener menos de 50 caracteres",
    ],
    emailRules: [
      (v) => !!v || "El correo es requerido",
      (v) => /.+@.+\..+/.test(v) || "Debe ser un correo válido",
    ],
    messageRules: [
      (v) => !!v || "El mensaje es requerido",
      (v) => v.length >= 10 || "El mensaje debe tener al menos 10 caracteres",
    ],
  }),
  methods: {
    async handleMessageSubmit() {
      if (!this.valid) return;

      this.sending = true;
      try {
        // Aquí irá la lógica de envío
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.$emit("message-sent", "Mensaje enviado exitosamente");
        this.messageForm = {
          name: "",
          email: "",
          message: "",
        };
      } catch (error) {
        this.$emit("message-error", "Error al enviar el mensaje");
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
