export default (data = {}) => {
    return {
      parking: {
          Pos : [
            {
              id: 101,
              name: "Parking",
              description: "Parking",
              icon: "mdi-parking",
              color: "warning",
              to: "Parking",
            },
            {
              id: 402,
              name: "Facturas electrónicas",
              description: "Lista de Facturas Electrónicas",
              icon: "mdi-receipt-text-outline",
              color: "white",
              to: "Doc_r",
              params: { docType: "FE", to: "Parking_docs" },
            },
            {
              id: 403,
              name: "Registradora",
              description: "Caja Registradora",
              icon: "mdi-cash-register",
              to: "Registradora",
              color: "warning",
              params: { docType: "FE", edit: true },
            },
          ]
        },
        '' : { 
          Sysop: [
        {
          id: 101000,
          name: "Compras",
          description: "Proveedores, Listas de precio proveedores",
          color: "success",
          icon: "mdi-truck-fast-outline",
          children: [
            {
              id: 101001,
              name: "Ordenes de compra",
              description: "Creear, editar o eliminar Ordenes de Compra",
              icon: "mdi-truck-cargo-container",
              color: "success",
              to: "Doc_r",
              params: { docType: "ORP", to: "docs" },
            },
            {
              id: 101002,
              name: "Proveedores",
              description:
                "Creear, editar o eliminar contactos de proveedores mayoristas",
              icon: "mdi-truck-cargo-container",
              color: "success",
              to: "Doc_r",
              params: { docType: "Proveedores", table: "clients", to: "Contacts" },
            },
          ],
        },
        {
          id: 102000,
          name: "Inventario",
          description: "Ingresos y Egresos de Inventario",
          icon: "mdi-forklift",
          color: "secondary",
          children: [
            {
              id: 1020001,
              name: "Productos",
              description: "Crear, editar o eliminar productos",
              icon: "mdi-store-outline",
              color: "warning",
              to: "Products",
            },
            {
              id: 1020002,
              name: "Materia Prima",
              description: "Crear, editar o eliminar Materia prima",
              icon: "mdi-store-outline",
              color: "warning",
              to: "RawMaterial",
            },

            {
              id: 302,
              name: "Ingreso de Mercancia",
              description: "Salidas de Inventario",
              icon: "mdi-forklift",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "I_IN", to: "docs" },
            },

            {
              id: 303,
              name: "Salida de Mercancia",
              description: "Salidas de Inventario",
              icon: "mdi-forklift",
              color: "secondary",
              to: "Doc_r",
              params: { docType: "I_OUT", to: "docs" },
            },
          ],
        },

        {
          id: 400,
          name: "Ventas",
          description: "Peidos, Despachos, Factura Electrónica, POS",
          icon: "mdi-receipt-text-check-outline",
          color: "white",
          children: [
            {
              id: 401,
              name: "Clientes",
              description: "Creear, editar o eliminar Restaurantes",
              icon: "mdi-account-group-outline",
              color: "white",
              to: "Doc_r",
              params: { docType: "Clientes", table: "clients", to: "Contacts" },
            },

            {
              id: 402,
              name: "Facturas electrónicas",
              description: "Lista de Facturas Electrónicas",
              icon: "mdi-truck-fast-outline",
              color: "white",
              to: "Doc_r",
              params: { docType: "FE", to: "docs" },
            },
            {
              id: 403,
              name: "POS",
              description: "Lista de Documentos POS",
              icon: "mdi-truck-fast-outline",
              color: "white",
              to: "Doc_r",
              params: { docType: "POS", to: "docs" },
            },
            {
              id: 404,
              name: "Pedidos",
              description: "Gestionar y crear pedidos de clientes",
              icon: "mdi-file-document-multiple-outline",
              color: "white",
              to: "Doc_r",
              params: { docType: "PC", to: "docs" },
            },
            {
              id: 405,
              name: "Lista de precios",
              description: "Creear, editar o eliminar lista de precios ",
              icon: "mdi-file-document-edit-outline",
              to: "Products",
              color: "white",
            },
            {
              id: 406,
              name: "Vendedores",
              description: "Creear, editar o eliminar vendedores",
              icon: "mdi-account-group-outline",
              color: "white",
              to: "Doc_r",
              params: { docType: "Vendedores", table: "clients", to: "Contacts" },
            },
          ],
        },

        {
          id: 105000,
          name: "Reportes",
          icon: "mdi-cog-transfer-outline",
          color: "info",
          children: [
            {
              id: 105001,
              name: "DashBoard",
              description: "Editar información de contacto",
              icon: "mdi-finance",
              color: "info",
              to: "DashBoard",
            },
            {
              id: 105002,
              name: "Reports",
              description: "Reports",
              color: "info",
              icon: "mdi-book-open-page-variant",
              to: "Reports",
            },
          ],
        },

        {
          id: 106000,
          name: "Configuración",
          description: "Proveedores, Listas de precio proveedores",
          icon: "mdi-cog-transfer-outline",
          color: "warning",
          children: [
            {
              id: 106001,
              name: "Mi Perfil",
              description: "Editar información de contacto",
              icon: "mdi-store-outline",
              color: "warning",
              to: "MyStore",
            },
            {
              id: 106002,
              name: "Tiendas",
              description: "Creear, editar o eliminar Empresas",
              icon: "mdi-account-group-outline",
              color: "warning",
              to: "Stores",
            },
            {
              id: 106003,
              name: "Usuarios",
              description: "Creear, editar o eliminar usuarios",
              icon: "mdi-account-group-outline",
              color: "warning",
              to: "Doc_r",
              params: { docType: "Usuarios", table: "users", to: "Users" },
            },
            {
              id: 106004,
              name: "Lista de Precios",
              description: "Editar información de contacto",
              icon: "mdi-store-outline",
              color: "warning",
              to: "Products",
            },
            {
              id: 106005,
              name: "Materia Prima",
              description: "Editar Materia Prima",
              icon: "mdi-store-outline",
              color: "warning",
              to: "RawMaterial",
            },
            {
              id: 106006,
              name: "PUC",
              description: "Plan Unico de Cuentas",
              icon: "mdi-calculator-variant-outline",
              color: "warning",
              to: "Puc",
            },
            {
              id: 106007,
              name: "Upload data",
              description: "Subir y actualizar informacion en bases de datos",
              icon: "mdi-database-arrow-up-outline",
              color: "warning",
              to: "AT_DB",
            },
            {
              id: 106008,
              name: "Asistentes",
              description: "Editar información de contacto",
              icon: "mdi-store-outline",
              color: "warning",
              to: "AiAdmin",
            },
          ],
        },

        {
          id: 907,
          name: "FacturaE",
          icon: "mdi-cog-transfer-outline",
          color: "warning",
          children: [
            {
              id: 981,
              name: "Empresas",
              description: "Creear, editar o eliminar lista de precios ",
              icon: "mdi-file-document-edit-outline",
              to: "Companies",
              color: "warning",
            },
            {
              id: 982,
              name: "Settings FE",
              description: "Creear, editar o eliminar lista de precios ",
              icon: "mdi-file-document-edit-outline",
              to: "SettingsFE",
              color: "warning",
            },
          ],
        },

        {
          id: 1070001,
          name: "Personalizados",
          icon: "mdi-cog-transfer-outline",
          color: "warning",
          children: [
            {
              id: 1070000,
              name: "Parking",
              description: "Parking",
              icon: "mdi-format-list-text",
              color: "warning",
              to: "Parking",
            },
            {
              id: 1070002,
              name: "Peluqueria",
              description: "Peluqueria",
              icon: "mdi-format-list-text",
              color: "warning",
              to: "Salon",
            },
            {
              id: 1070003,
              name: "Comanda",
              description: "Comanda",
              icon: "mdi-format-list-text",
              color: "warning",
              to: "Comanda",
            },

            {
              id: 1070004,
              name: "Registradora",
              description: "Caja Registradora",
              icon: "mdi-cash-register",
              to: "Registradora",
              color: "warning",
              params: { docType: "POS", edit: true },
            },
            {
              id: 1070005,
              name: "Catalogo",
              description: "Catalogo de productos",
              icon: "mdi-book-open-page-variant",
              to: "Catalogo",
              color: "warning",
            },
            {
              id: 1070006,
              name: "Contratos",
              description: "Contratos",
              icon: "mdi-book-open-page-variant",
              to: "Contratos",
              color: "warning",
            },
            {
              id: 1070007,
              name: "Agente",
              description: "Agente",
              icon: "mdi-book-open-page-variant",
              to: "Agente",
              color: "warning",
            },
            {
              id: 1070008,
              name: "Catálogo Vehiculos",
              description: "Catalogo de productos",
              icon: "mdi-file-document-edit-outline",
              to: "Vehiculos",
              color: "secondary",
            },
            {
              id: 1070009,
              name: "Vehículos",
              description: "Vehículos en inventario",
              icon: "mdi-chart-multiple",
              color: "secondary",
              to: "Vehicles",
            },
            {
              id: 1070010,
              name: "CallCenter",
              description: "Gestion de llamadas",
              icon: "mdi-chart-multiple",
              color: "secondary",
              to: "CallCenter",
            },
            {
              id: 1070011,
              name: "Campaigns",
              description: "Gestion de Campaigns",
              icon: "mdi-chart-multiple",
              color: "secondary",
              to: "Campaigns",
            },
            {
              id: 1070012,
              name: "CRM Dashboard",
              description: "Gestion de Campaigns",
              icon: "mdi-chart-multiple",
              color: "secondary",
              to: "crmDashboar",
            },
            {
              id: 1070013,
              name: "People",
              description: "People",
              icon: "mdi-chart-multiple",
              color: "secondary",
              to: "People",
            },
            {
              id: 1070014,
              name: "People_b",
              description: "Peopleb",
              icon: "mdi-chart-multiple",
              color: "secondary",
              to: "Peopleb",
            },
            {
              id: 1070015,
              name: "GymTracker",
              description: "GymTracker",
              icon: "mdi-chart-multiple",
              color: "secondary",
              to: "GymTracker",
            },
            {
              id: 1070016,
              name: "GymTrackerV2",
              description: "GymTrackerV2",
              icon: "mdi-chart-multiple",
              color: "secondary",
              to: "GymTrackerV2",
            },
            {
              id: 1070017,
              name: "FacialRecognition",
              description: "Facial Recognition",
              icon: "mdi-chart-multiple",
              color: "secondary",
              to: "Peopleb",
            },
            {
              id: 1070018,
              name: "Crops",
              description: "Crops",
              icon: "mdi-chart-multiple",
              color: "secondary",
              to: "Crops",
            },
            {
              id: 1070019,
              name: "Polygons",
              description: "Polygons",
              icon: "mdi-chart-multiple",
              color: "secondary",
              to: "Polygons",
            },
          ],
        },
      ],  
      Ventas: [

        {
          id: 1100000,
          name: "CallCenter",
          description: "Gestion de llamadas",
          icon: "mdi-chart-multiple",
          color: "secondary",
          to: "CallCenter",
        },
        {
          id: 1100001,
          name: "Clientes",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-account-group-outline",
          color: "white",
          to: "Doc_r",
          params: { docType: "Clientes", table: "clients", to: "Contacts" },
        },
        {
          id: 1100002,
          name: "CRM Dashboard",
          description: "Gestion de Campaigns",
          icon: "mdi-chart-multiple",
          color: "secondary",
          to: "crmDashboar",
        },


      ],
        },

        ...data
    }
}