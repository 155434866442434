<template>
  <div class="feed-container">
    <!-- Header de categorías -->
    <v-container class="category-header py-4" style="background: white">
      <v-row justify="center">
        <v-col cols="12" lg="12">
          <v-chip-group
            v-model="selectedCategory"
            active-class="primary--text"
            show-arrows
            mandatory
          >
            <v-chip
              v-for="category in categories"
              :key="category"
              filter
              outlined
              class="font-weight-medium px-4"
            >
              <v-icon left :color="getCategoryColor(category)" small>
                {{ getCategoryIcon(category) }}
              </v-icon>
              {{ category }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
    </v-container>

    <!-- Contenido principal -->
    <v-container class="py-6">
      <post-slideshow :account-id="accountId" />
      <v-row justify="center">
        <v-col cols="12" lg="10">
          <!-- Estados de carga y error -->
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            class="d-flex mx-auto"
          ></v-progress-circular>
          <v-alert v-else-if="error" type="error">{{ error }}</v-alert>

          <template v-else>
            <!-- Post Principal -->
            <v-row v-if="featuredPost" class="mb-12">
              <v-col cols="12">
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <v-card
                      class="featured-post"
                      :elevation="hover ? 8 : 2"
                      :class="{ 'on-hover': hover }"
                    >
                      <v-row no-gutters>
                        <v-col cols="12" class="position-relative">
                          <v-img
                            :src="getImageUrl(featuredPost)"
                            height="500"
                            :class="['featured-image', { 'on-hover': hover }]"
                          >
                            <div class="featured-overlay"></div>
                            <div class="featured-content">
                              <v-chip
                                :color="getCategoryColor(featuredPost.category)"
                                text-color="white"
                                small
                                class="mb-4"
                              >
                                {{ featuredPost.category }}
                              </v-chip>

                              <h1
                                class="text-h3 font-weight-bold white--text mb-4 featured-title"
                              >
                                {{ featuredPost.title }}
                              </h1>

                              <div class="featured-excerpt white--text mb-6">
                                {{ getExcerpt(featuredPost.content, 200) }}
                              </div>

                              <div
                                class="d-flex align-items-center justify-space-between"
                              >
                                <div class="d-flex align-center white--text">
                                  <v-avatar size="40" class="mr-3">
                                    <v-img :src="featuredPost.authorAvatar"></v-img>
                                  </v-avatar>
                                  <div>
                                    <div class="font-weight-medium mb-1">
                                      {{ featuredPost.author_name }}
                                    </div>
                                    <div class="caption">
                                      {{ formatDate(featuredPost.createdAt) }}
                                    </div>
                                  </div>
                                </div>

                                <div class="d-flex align-center">
                                  <v-btn
                                    color="white"
                                    class="mr-2"
                                    outlined
                                    @click.stop="handleLike(featuredPost)"
                                  >
                                    <v-icon
                                      left
                                      :color="featuredPost.liked ? 'error' : 'white'"
                                    >
                                      {{
                                        featuredPost.liked
                                          ? "mdi-heart"
                                          : "mdi-heart-outline"
                                      }}
                                    </v-icon>
                                    <span class="white--text">{{
                                      featuredPost.likes
                                    }}</span>
                                  </v-btn>

                                  <v-btn
                                    color="white"
                                    outlined
                                    @click.stop="toggleContent(featuredPost.id)"
                                  >
                                    Leer más
                                    <v-icon right>mdi-arrow-right</v-icon>
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                          </v-img>
                        </v-col>
                      </v-row>
                    </v-card>
                  </template>
                </v-hover>
              </v-col>
            </v-row>

            <!-- Grid de Posts -->
            <v-row>
              <v-col v-for="post in regularPosts" :key="post.id" cols="12" md="6" lg="4">
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <v-card
                      class="mb-8 post-card"
                      :elevation="hover ? 8 : 2"
                      :class="{ 'on-hover': hover }"
                    >
                      <v-img :src="getImageUrl(post)" height="200" class="post-image">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <v-card-title class="py-2">
                        <v-chip
                          :color="getCategoryColor(post.category)"
                          text-color="white"
                          x-small
                          class="mb-2"
                        >
                          {{ post.category }}
                        </v-chip>
                        <h3 class="text-h6 font-weight-bold post-title">
                          {{ post.title }}
                        </h3>
                      </v-card-title>

                      <v-card-text class="pt-0">
                        <div class="d-flex align-center mb-4">
                          <v-avatar size="24" class="mr-2">
                            <v-img :src="post.authorAvatar"></v-img>
                          </v-avatar>
                          <span class="caption font-weight-medium">{{
                            post.author_name
                          }}</span>
                          <v-spacer></v-spacer>
                          <v-icon small class="mr-1">mdi-clock-outline</v-icon>
                          <span class="caption">{{ formatDate(post.createdAt) }}</span>
                        </div>

                        <div class="post-excerpt">
                          <div v-html="getExcerpt(post.content, 150)"></div>
                        </div>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn text small @click="handleLike(post)">
                          <v-icon :color="post.liked ? 'error' : ''" small class="mr-1">
                            {{ post.liked ? "mdi-heart" : "mdi-heart-outline" }}
                          </v-icon>
                          {{ post.likes }}
                        </v-btn>
                        <v-btn text small>
                          <v-icon small class="mr-1">mdi-comment-outline</v-icon>
                          {{ post.comments.length }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text small color="primary" @click="toggleContent(post.id)">
                          Leer más
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-hover>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog para ver post completo -->
    <v-dialog
      v-model="showFullPost"
      max-width="800"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card v-if="selectedPost">
        <!-- Toolbar para versión móvil -->
        <v-toolbar v-if="$vuetify.breakpoint.xsOnly" dark color="primary" fixed>
          <v-btn icon dark @click="showFullPost = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-truncate">{{
            selectedPost.title
          }}</v-toolbar-title>
        </v-toolbar>

        <!-- Imagen del post -->
        <v-img
          v-if="selectedPost.image_url"
          :src="getImageUrl(selectedPost)"
          max-height="300"
          class="mb-4"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>

        <!-- Contenido del Post -->
        <v-card-title v-if="!$vuetify.breakpoint.xsOnly" class="text-h4 pb-2">
          {{ selectedPost.title }}
        </v-card-title>

        <v-card-text>
          <!-- Info del autor -->
          <v-list-item class="px-0 mb-4">
            <v-list-item-avatar size="40">
              <v-img :src="selectedPost.authorAvatar"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                {{ selectedPost.author_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ formatDate(selectedPost.createdAt) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- Contenido del post -->
          <article class="post-content" v-html="selectedPost.content"></article>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-btn
            text
            @click="handleLike(selectedPost)"
            :color="selectedPost.liked ? 'error' : ''"
          >
            <v-icon left>
              {{ selectedPost.liked ? "mdi-heart" : "mdi-heart-outline" }}
            </v-icon>
            {{ selectedPost.likes }}
          </v-btn>

          <v-btn
            text
            @click="isSpeaking ? stopSpeech() : startSpeech()"
            :loading="isLoading"
            :color="isSpeaking ? 'error' : 'primary'"
          >
            <v-icon left>
              {{ isSpeaking ? "mdi-stop" : "mdi-play" }}
            </v-icon>
            {{
              isLoading
                ? `Leyendo ${currentChunk}/${totalChunks}`
                : isSpeaking
                ? "Detener"
                : "Leer"
            }}
          </v-btn>

          <v-select
            v-if="speechVoices.length > 1"
            v-model="selectedVoice"
            :items="speechVoices"
            item-text="name"
            return-object
            label="Seleccionar voz"
            dense
            class="ml-4"
            style="max-width: 200px"
            hide-details
          ></v-select>

          <v-spacer></v-spacer>

          <v-btn
            v-if="!$vuetify.breakpoint.xsOnly"
            text
            @click="showFullPost = false"
            color="primary"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiClient from "@/services/api.js";
import PostSlideshow from "./PostSlideshow.vue";

export default {
  name: "AccountFeed",
  components: {
    PostSlideshow,
  },
  props: {
    accountId: {
      type: String,
      required: false,
      default: "308581",
    },
    embedded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      yapps: [],
      loading: false,
      error: null,
      baseUrl: process.env.VUE_APP_API_URL || "https://ai-pos.co",
      selectedCategory: null,
      categories: [],
      expandedPosts: {},
      showFullPost: false,
      selectedPost: null,
      isSpeaking: false,
      speechVoices: [],
      selectedVoice: null,
      error: null,
      isLoading: false,
      currentChunk: 0,
      totalChunks: 0,
    };
  },
  computed: {
    filteredYapps() {
      if (!this.selectedCategory) return this.yapps;
      return this.yapps.filter((yapp) => yapp.category === this.selectedCategory);
    },
    featuredPost() {
      return this.filteredYapps[0] || null;
    },
    regularPosts() {
      return this.filteredYapps.slice(1);
    },
    canShare() {
      return navigator.share !== undefined;
    },
  },
  created() {
    this.initVoices();
  },
  mounted() {
    this.fetchYapps();
    if (this.embedded) {
      window.addEventListener("resize", this.notifyHeight);
      this.$nextTick(() => {
        this.notifyHeight();
      });
    }
  },
  methods: {
    splitTextIntoChunks(text) {
      const cleanText = this.cleanTextForSpeech(text);
      const sentences = cleanText.match(/[^.!?]+[.!?]+/g) || [cleanText];
      const chunks = [];
      let currentChunk = "";

      for (const sentence of sentences) {
        if ((currentChunk + sentence).length > 200) {
          chunks.push(currentChunk.trim());
          currentChunk = sentence;
        } else {
          currentChunk += " " + sentence;
        }
      }
      if (currentChunk) chunks.push(currentChunk.trim());

      return chunks;
    },

    async speakChunk(chunk, voice) {
      return new Promise((resolve, reject) => {
        const utterance = new SpeechSynthesisUtterance(chunk);
        utterance.voice = voice;
        utterance.rate = 1.0;
        utterance.pitch = 1.0;
        utterance.onend = resolve;
        utterance.onerror = reject;
        window.speechSynthesis.speak(utterance);
      });
    },
    notifyHeight() {
      if (window.parent && window.parent !== window) {
        window.parent.postMessage(
          {
            type: "resize",
            height: this.$el.scrollHeight,
          },
          "*"
        );
      }
    },
    getCategoryIcon(category) {
      const icons = {
        Noticias: "mdi-newspaper",
        Eventos: "mdi-calendar-star",
        Productos: "mdi-shopping",
        default: "mdi-tag",
      };
      return icons[category] || icons.default;
    },
    getCategoryColor(category) {
      const colors = {
        Noticias: "indigo",
        Eventos: "deep-purple",
        Productos: "blue",
        default: "grey",
      };
      return colors[category] || colors.default;
    },
    getImageUrl(post) {
      if (!post.image_url) return "https://picsum.photos/600/300";
      return `${this.baseUrl}/yapps_img/${post.account}/${post.category_id}/${post.image_url}`;
    },
    getExcerpt(content, length = 200) {
      if (!content) return "";
      const text = content.replace(/<[^>]*>/g, "");
      return text.length > length ? text.slice(0, length) + "..." : text;
    },
    formatDate(date) {
      if (!date) return "";
      try {
        const dateObj = new Date(date);
        if (isNaN(dateObj.getTime())) {
          return "";
        }
        const options = {
          day: "numeric",
          month: "long",
          year: "numeric",
        };
        return dateObj.toLocaleDateString("es-ES", options);
      } catch (error) {
        console.error("Error formatting date:", error);
        return "";
      }
    },
    async fetchYapps() {
      this.loading = true;
      this.error = null;
      try {
        const response = await apiClient.get("/yapps", {
          params: { account: this.accountId },
        });
        this.yapps = response.data.map((yapp) => ({
          ...yapp,
          createdAt: yapp.created_at ? new Date(yapp.created_at).toISOString() : null,
          authorAvatar: "https://picsum.photos/40",
          likes: yapp.likes || 0,
          liked: false,
          comments: yapp.comments || [],
        }));
        this.categories = ["Todos", ...new Set(this.yapps.map((yapp) => yapp.category))];
      } catch (error) {
        console.error("Error fetching yapps:", error);
        this.error =
          "No se pudieron cargar las publicaciones. Por favor, intente más tarde.";
      } finally {
        this.loading = false;
      }
    },
    async handleLike(yapp) {
      try {
        if (yapp.liked) {
          await apiClient.delete(`/yapps/${yapp.id}/likes`);
          yapp.likes--;
        } else {
          await apiClient.post(`/yapps/${yapp.id}/likes`);
          yapp.likes++;
        }
        yapp.liked = !yapp.liked;
      } catch (error) {
        console.error("Error handling like:", error);
      }
    },
    toggleContent(yappId) {
      this.selectedPost = this.yapps.find((yapp) => yapp.id === yappId);
      this.showFullPost = true;
    },
    async sharePost() {
      if (!this.selectedPost) return;
      try {
        await navigator.share({
          title: this.selectedPost.title,
          text: this.getExcerpt(this.selectedPost.content, 150),
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    },

    // Text-to-Speech methods
    initVoices() {
      if (typeof window === "undefined" || !window.speechSynthesis) {
        console.warn("Speech synthesis no está soportado en este navegador");
        return;
      }

      const loadVoices = () => {
        const voices = window.speechSynthesis.getVoices();
        console.log("Buscando voces en español...");

        // Filtrar solo voces en español
        this.speechVoices = voices
          .filter((voice) => {
            const isSpanish =
              voice.lang.startsWith("es") ||
              voice.lang.startsWith("sp") ||
              voice.name.toLowerCase().includes("spanish") ||
              voice.name.toLowerCase().includes("español");

            if (isSpanish) {
              console.log(`Voz en español encontrada: ${voice.name} (${voice.lang})`);
            }
            return isSpanish;
          })
          .map((voice) => ({
            name: `${voice.name} (${voice.lang})`,
            lang: voice.lang,
            voiceURI: voice.voiceURI,
            _voice: voice,
          }));

        if (this.speechVoices.length === 0) {
          console.warn("No se encontraron voces en español");
          this.error = "No hay voces en español disponibles en su navegador.";
          return;
        }

        // Cargar la voz preferida guardada
        this.loadPreferredVoice();
      };

      // Cargar voces inmediatamente si están disponibles
      loadVoices();

      // Escuchar el evento onvoiceschanged (necesario en Chrome)
      if (window.speechSynthesis.onvoiceschanged !== undefined) {
        window.speechSynthesis.onvoiceschanged = loadVoices;
      }
    },

    loadPreferredVoice() {
      try {
        const savedVoiceURI = localStorage.getItem("preferredVoiceURI");
        if (savedVoiceURI) {
          const savedVoice = this.speechVoices.find((v) => v.voiceURI === savedVoiceURI);
          if (savedVoice) {
            this.selectedVoice = savedVoice;
            console.log("Voz preferida cargada:", savedVoice.name);
            return;
          }
        }
      } catch (e) {
        console.warn("Error al cargar la voz preferida:", e);
      }

      // Si no hay voz guardada o hubo error, seleccionar la primera voz disponible
      const spanishVoice = this.speechVoices.find((v) => v.lang === "es-ES");
      this.selectedVoice = spanishVoice || this.speechVoices[0];
      console.log(`Voz seleccionada por defecto: ${this.selectedVoice.name}`);
    },

    savePreferredVoice(voice) {
      try {
        if (voice && voice.voiceURI) {
          localStorage.setItem("preferredVoiceURI", voice.voiceURI);
          console.log("Voz preferida guardada:", voice.name);
        }
      } catch (e) {
        console.warn("Error al guardar la voz preferida:", e);
      }
    },

    async startSpeech() {
      if (!this.selectedPost || !this.selectedVoice) return;

      this.isLoading = true;
      this.isSpeaking = true;

      try {
        const chunks = this.splitTextIntoChunks(this.selectedPost.content);
        this.currentChunk = 0;
        this.totalChunks = chunks.length;

        for (let i = 0; i < chunks.length; i++) {
          if (!this.isSpeaking) break;
          this.currentChunk = i + 1;
          await this.speakChunk(chunks[i], this.selectedVoice._voice);
        }
      } catch (error) {
        console.error("Error en la lectura:", error);
      } finally {
        this.isLoading = false;
        this.isSpeaking = false;
        this.currentChunk = 0;
      }
    },

    stopSpeech() {
      window.speechSynthesis.cancel();
      this.isSpeaking = false;
    },

    cleanTextForSpeech(content) {
      if (!content) return "";

      let text = content.replace(/<[^>]*>/g, " ");
      text = text
        .replace(/&nbsp;/g, " ")
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/\s+/g, " ")
        .trim();

      return text;
    },
  },
  beforeDestroy() {
    if (this.embedded) {
      window.removeEventListener("resize", this.notifyHeight);
    }
    if (window.speechSynthesis) {
      window.speechSynthesis.cancel();
    }
  },
  watch: {
    showFullPost(newVal) {
      if (!newVal) {
        this.stopSpeech();
      }
    },
    selectedVoice: {
      handler(newVoice) {
        if (newVoice) {
          this.savePreferredVoice(newVoice);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.feed-container {
  background-color: #f5f7fa;
}

.category-header {
  position: sticky;
  top: 0px;
  z-index: 4;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.featured-post {
  overflow: hidden;
  transition: all 0.3s ease;
}

.featured-image {
  position: relative;
}

.featured-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: 1;
}

.featured-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  z-index: 2;
}

.featured-title {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.featured-excerpt {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.post-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.post-card.on-hover {
  transform: translateY(-4px);
}

.post-image {
  transition: all 0.3s ease;
}

.post-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4;
  margin-top: 0.5rem;
}

.post-excerpt {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1.6;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 1rem;
}

.full-post-dialog {
  max-height: 90vh;
  overflow-y: auto;
}

.post-dialog-image {
  width: 100%;
  object-fit: cover;
}

.full-post-content {
  font-size: 1.1rem;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.87);
}

.full-post-content :deep(h2),
.full-post-content :deep(h3) {
  margin: 1.5rem 0 1rem;
  font-weight: 600;
}

.full-post-content :deep(p) {
  margin-bottom: 1.25rem;
}

.full-post-content :deep(img) {
  max-width: 100%;
  height: auto;
  margin: 1.5rem 0;
  border-radius: 4px;
}

.full-post-content :deep(ul),
.full-post-content :deep(ol) {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.full-post-content :deep(li) {
  margin-bottom: 0.5rem;
}

.full-post-content :deep(blockquote) {
  margin: 1.5rem 0;
  padding: 1rem 1.5rem;
  border-left: 4px solid var(--v-primary-base);
  background-color: rgba(0, 0, 0, 0.03);
  font-style: italic;
}

@media (max-width: 960px) {
  .featured-content {
    padding: 1.5rem;
  }

  .featured-title {
    font-size: 2rem !important;
  }

  .featured-excerpt {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .category-header {
    top: 56px;
  }

  .featured-content {
    padding: 1rem;
  }

  .featured-title {
    font-size: 1.5rem !important;
  }

  .featured-image {
    height: 400px !important;
  }

  .post-title {
    font-size: 1.25rem !important;
  }

  .full-post-content {
    font-size: 1rem;
    line-height: 1.6;
  }
}
</style>
