<template>
  <v-app>
    <div v-if="!manifest || !company">
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
              size="70"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <!-- Barra de navegación -->
      <v-app-bar
        :color="manifest.theme_color || 'primary'"
        dark
        app
        elevate-on-scroll
        :height="64"
        :class="{ 'v-app-bar--transparent': !isScrolled }"
        prominent
      >
        <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer">
        </v-app-bar-nav-icon>

        <v-toolbar-title class="white--text d-flex align-center">
          <v-img
            :src="manifest.logo_bar"
            max-width="40"
            max-height="40"
            contain
            class="mr-2"
          ></v-img>
          {{ company.name }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div class="hidden-sm-and-down">
          <template v-if="!isAuthenticated">
            <v-btn text class="white--text text-transform-none ml-4" @click="goToLogin">
              <v-icon left>mdi-login</v-icon>
              Iniciar Sesión
            </v-btn>
          </template>
          <template v-else>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="white--text text-transform-none ml-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>mdi-account-circle</v-icon>
                  {{ profile ? profile.user_name || "Usuario" : "Usuario" }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ profile.user_name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ profile.user_email }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="goToDashboard">
                  <v-list-item-icon>
                    <v-icon>mdi-view-dashboard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item>

                <v-list-item @click="handleLogout">
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Cerrar Sesión</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </div>
      </v-app-bar>

      <!-- Navigation Drawer -->
      <v-navigation-drawer v-model="drawer" app temporary class="hidden-md-and-up">
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="manifest.logo_bar"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="title">{{ company.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="scrollTo('home')">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollTo('products')">
            <v-list-item-icon>
              <v-icon>mdi-store</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Productos</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollTo('contact')">
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contacto</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <template v-if="!isAuthenticated">
            <v-list-item @click="goToLogin">
              <v-list-item-icon>
                <v-icon>mdi-login</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Iniciar Sesión</v-list-item-title>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ profile.user_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ profile.user_email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="goToDashboard">
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item>

            <v-list-item @click="handleLogout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Cerrar Sesión</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>

      <!-- Hero Section -->
      <v-parallax
        :src="manifest.hero_image || '/default-hero.jpg'"
        height="100vh"
        class="hero-section"
      >
        <v-overlay
          absolute
          :opacity="0.3"
          :gradient="'to bottom, rgba(0,0,0,.7), rgba(0,0,0,.3)'"
        >
          <v-container fill-height>
            <v-row class="fill-height" align="center" justify="center">
              <v-col cols="12" md="8" class="text-center">
                <h1 class="text-h2 font-weight-bold white--text mb-4">
                  {{ company.name }}
                </h1>
                <h4 class="text-h5 white--text mb-8">
                  {{ company.tagline || "Bienvenido a nuestro sitio web" }}
                </h4>
                <v-btn
                  x-large
                  color="secondary"
                  class="text-transform-none"
                  elevation="2"
                  @click="scrollTo('products')"
                >
                  <v-icon left>mdi-shopping</v-icon>
                  Explorar Productos
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-overlay>
      </v-parallax>

      <!-- About Section -->
      <section id="about" class="company-profile">
        <v-container fluid class="pa-0">
          <v-row no-gutters>
            <v-col cols="12">
              <v-sheet
                class="company-banner position-relative overflow-hidden"
                height="300"
                :color="manifest.theme_color || 'primary'"
              >
                <div class="banner-pattern"></div>
                <v-container class="fill-height">
                  <v-row align="center" justify="start">
                    <v-col cols="12" md="8" class="position-relative">
                      <v-avatar size="120" class="company-logo elevation-4 mb-4">
                        <v-img
                          :src="manifest.logo_bar"
                          :alt="company.name"
                          contain
                          class="white"
                        ></v-img>
                      </v-avatar>
                      <h1 class="text-h3 font-weight-bold white--text mb-2">
                        {{ company.name }}
                      </h1>
                      <div class="d-flex align-center flex-wrap">
                        <v-chip class="mr-2 mb-2" color="secondary" label small>
                          <v-icon left small>mdi-check-decagram</v-icon>
                          Verificado
                        </v-chip>
                        <v-chip class="mr-2 mb-2" color="white" label small>
                          <v-icon left small>mdi-domain</v-icon>
                          {{ company.industry || "Comercio" }}
                        </v-chip>
                        <v-chip class="mr-2 mb-2" color="white" label small>
                          <v-icon left small>mdi-map-marker</v-icon>
                          {{ company.city }}
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section id="yapp">
        <account-feed :account-id="accountId" />
      </section>

      <!-- Footer -->

      <v-footer :color="manifest.theme_color || 'primary'" class="modern-footer">
        <v-container>
          <v-row class="footer-content py-12">
            <!-- Columna de Contacto -->
            <v-col cols="12" md="4" class="contact-section">
              <div class="contact-card">
                <div class="d-flex align-center mb-6">
                  <v-img
                    :src="manifest.logo_bar"
                    max-width="40"
                    contain
                    class="mr-3"
                  ></v-img>
                  <h3 class="text-h5 font-weight-medium white--text">
                    {{ company.name }}
                  </h3>
                </div>

                <v-list dense dark color="transparent" class="pa-0 contact-list">
                  <v-list-item class="pl-0">
                    <v-list-item-icon class="mr-3">
                      <v-icon small>mdi-map-marker</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-body-2">
                        {{ company.address }}<br />{{ company.city }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="pl-0">
                    <v-list-item-icon class="mr-3">
                      <v-icon small>mdi-phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-body-2">
                        <a :href="`tel:${company.mobile}`" class="contact-link">
                          {{ company.mobile }}
                        </a>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="pl-0">
                    <v-list-item-icon class="mr-3">
                      <v-icon small>mdi-email</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-body-2">
                        <a :href="`mailto:${company.email}`" class="contact-link">
                          {{ company.email }}
                        </a>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="pl-0">
                    <v-list-item-icon class="mr-3">
                      <v-icon small>mdi-clock-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-body-2">
                        {{ company.schedule || "Lun - Vie: 8:00 AM - 6:00 PM" }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-btn
                  block
                  color="secondary"
                  class="mt-6 text-transform-none save-contact-btn"
                  elevation="2"
                >
                  <v-icon left>mdi-card-account-details</v-icon>
                  Guardar Información de Contacto
                </v-btn>
              </div>
            </v-col>

            <!-- Columna de Soporte -->
            <v-col cols="12" md="4" class="support-section">
              <div class="support-card">
                <h3 class="text-h5 white--text mb-6">Centro de Ayuda</h3>

                <div class="support-options">
                  <v-row>
                    <v-col cols="12" md="12">
                      <!-- Live Support -->
                      <div class="support-option mb-6">
                        <div class="d-flex align-center mb-3">
                          <v-icon color="secondary" size="32" class="mr-3"
                            >mdi-headset</v-icon
                          >
                          <div>
                            <h4 class="text-subtitle-1 white--text mb-1">
                              Soporte en Vivo
                            </h4>
                            <p class="text-caption grey--text text--lighten-1">
                              Chatea con nuestro equipo de soporte en tiempo real
                            </p>
                          </div>
                        </div>
                        <v-btn
                          block
                          outlined
                          color="white"
                          class="text-transform-none support-btn"
                          @click="$emit('open-live-support')"
                        >
                          <v-icon left>mdi-message-text</v-icon>
                          Iniciar Chat
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="12" md="12">
                      <!-- Virtual Assistant -->
                      <div class="support-option">
                        <div class="d-flex align-center mb-3">
                          <v-icon color="secondary" size="32" class="mr-3"
                            >mdi-robot</v-icon
                          >
                          <div>
                            <h4 class="text-subtitle-1 white--text mb-1">
                              Asistente Virtual
                            </h4>
                            <p class="text-caption grey--text text--lighten-1">
                              Obtén ayuda instantánea con nuestra IA
                            </p>
                          </div>
                        </div>
                        <v-btn
                          block
                          outlined
                          color="white"
                          class="text-transform-none support-btn"
                          @click="$emit('open-ai-assistant')"
                        >
                          <v-icon left>mdi-artificial-intelligence</v-icon>
                          Consultar AI
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>

              <!-- Stats Cards -->
              <v-row class="mt-6">
                <v-col cols="6">
                  <div class="stat-card">
                    <div class="stat-icon">
                      <v-icon color="secondary">mdi-clock-check</v-icon>
                    </div>
                    <div class="stat-content">
                      <div class="stat-value">24/7</div>
                      <div class="stat-label">Soporte</div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="stat-card">
                    <div class="stat-icon">
                      <v-icon color="secondary">mdi-account-check</v-icon>
                    </div>
                    <div class="stat-content">
                      <div class="stat-value">< 5min</div>
                      <div class="stat-label">Tiempo de Respuesta</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" class="email-section">
              <EmailSection
                @message-sent="showSnackbar"
                @message-error="showErrorSnackbar"
              />
            </v-col>
          </v-row>

          <v-divider class="my-6 opacity-3"></v-divider>

          <div class="footer-bottom py-4 text-center text-md-left">
            <v-row align="center" justify="space-between">
              <v-col cols="12" md="6">
                <span class="text-caption grey--text text--lighten-1">
                  &copy; {{ new Date().getFullYear() }} {{ company.name }}. Todos los
                  derechos reservados.
                </span>
              </v-col>
              <v-col cols="12" md="6" class="text-md-right">
                <v-btn text x-small href="/terminos" class="px-2">Términos</v-btn>
                <v-btn text x-small href="/privacidad" class="px-2">Privacidad</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-footer>

      <!-- Snackbar para notificaciones -->
      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"> Cerrar </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import { webserver } from "../services/webserver.js";
import AccountFeed from "../views/yapp/feed.vue";
import SaveContactButton from "./Contact_card.vue";
import EmailSection from "./Email_section.vue";

export default {
  name: "LandingPage",
  components: {
    AccountFeed,
    SaveContactButton,
    EmailSection,
  },
  data() {
    return {
      isScrolled: false,
      navigationLinks: [],
      accountId: "308581",
      manifest: null,
      company: null,
      products: [],
      groupedProducts: {},
      categoriesList: [],
      drawer: false,
      snackbar: {
        show: false,
        text: "",
        color: "success",
        timeout: 3000,
      },
      loading_status: false,
      email: "",
      sending: false,
      companyStats: [
        {
          icon: "mdi-clock-check",
          value: "24/7",
          label: "Soporte",
        },
        {
          icon: "mdi-account-check",
          value: "100%",
          label: "Satisfacción",
        },
        {
          icon: "mdi-package-variant-closed",
          value: "50+",
          label: "Productos",
        },
        {
          icon: "mdi-truck-delivery",
          value: "24h",
          label: "Entrega",
        },
      ],

      messageForm: {
        name: "",
        email: "",
        message: "",
      },
    };
  },

  computed: {
    host() {
      return this.$store.getters.getHost;
    },
    profile() {
      return this.$store.getters.getProfile;
    },
    isAuthenticated() {
      const profile = this.$store.getters.getProfile;
      return profile !== null && profile !== undefined && Object.keys(profile).length > 0;
    },
    calculateTotalProducts() {
      return Object.values(this.groupedProducts).reduce(
        (acc, curr) => acc + curr.length,
        0
      );
    },
  },

  beforeMount() {
    this.checkAuthStatus();
  },

  mounted() {
    this.initializeComponent();
  },

  methods: {
    handleMessageSent(message) {
      this.showSnackbar(message, "success");
    },
    handleMessageError(error) {
      this.showSnackbar(error, "error");
    },

    showSnackbar(message) {
      this.$emit("show-snackbar", {
        text: message,
        color: "success",
      });
    },
    showErrorSnackbar(message) {
      this.$emit("show-snackbar", {
        text: message,
        color: "error",
      });
    },
    checkAuthStatus() {
      const storedProfile = localStorage.getItem("sc_c");
      if (storedProfile) {
        try {
          const profile = JSON.parse(
            this.CryptoJS.AES.decrypt(storedProfile, process.env.VUE_APP_QR4).toString(
              this.CryptoJS.enc.Utf8
            )
          );
          this.$store.dispatch("setProfile", profile);

          const storedCompany = localStorage.getItem("sc_s");
          if (storedCompany) {
            const company = JSON.parse(
              this.CryptoJS.AES.decrypt(storedCompany, process.env.VUE_APP_QR4).toString(
                this.CryptoJS.enc.Utf8
              )
            );
            this.$store.dispatch("setCompany", company);
          }
        } catch (error) {
          console.error("Error decoding stored credentials:", error);
          this.handleLogout();
        }
      }
    },

    initializeComponent() {
      this.get_company();
      this.$store.dispatch("setNavStatus", false);
      this.$store.dispatch("set_menu", false);
      this.$vuetify.goTo(0);
    },

    async get_company() {
      const qry = {
        table: "stores",
        filters: [{ field: "url", operator: "=", value: this.host }],
      };
      try {
        const data = await new Promise((resolve, reject) => {
          webserver(
            "get_table",
            qry,
            function (data) {
              resolve(data);
            },
            function () {
              reject([]);
            }
          );
        });

        if (data.length > 0) {
          this.company = data[0];
          this.get_products();
        } else {
          this.showSnackbar("No se encontró información de la compañía.", "error");
        }
      } catch (error) {
        this.showSnackbar("Error al obtener la información de la compañía.", "error");
      }
    },

    async get_products() {
      if (this.company && this.company.account) {
        const qry = {
          account: this.company.account,
          table: "products",
          filters: [
            {
              field: "account",
              operator: "=",
              value: this.company.account,
            },
          ],
        };
        try {
          const data = await new Promise((resolve, reject) => {
            webserver(
              "get_table",
              qry,
              function (data) {
                resolve(data);
              },
              function () {
                reject([]);
              }
            );
          });

          this.groupedProducts = {};
          data.forEach((product) => {
            const category = product.category || "Otros";
            if (!this.groupedProducts[category]) {
              this.groupedProducts[category] = [];
            }
            this.groupedProducts[category].push({
              ...product,
              price: parseFloat(product.price),
              cost_uom: product.cost_uom ? parseFloat(product.cost_uom) : 0,
              image: this.getImagePhoto(product.account, product.store, product.code),
            });
          });

          this.categoriesList = Object.keys(this.groupedProducts);
        } catch (error) {
          this.showSnackbar("Error al cargar los productos.", "error");
        }
      }
    },

    getImagePhoto(account, store, code) {
      const timestamp = new Date().getTime();
      return `https://ai-pos.co/photos/${account}/${store}/${code}/${code}_1.webp?t=${timestamp}`;
    },

    scrollTo(section) {
      const element = document.getElementById(section);
      if (element) {
        this.$vuetify.goTo(element, {
          duration: 1000,
          offset: 64,
          easing: "easeInOutCubic",
        });
      }
      this.drawer = false;
    },

    goToLogin() {
      this.$router.push("/Login");
    },

    goToDashboard() {
      this.$router.push("/Welcome");
    },

    handleLogout() {
      localStorage.removeItem("sc_c");
      localStorage.removeItem("sc_s");

      this.$store.dispatch("setProfile", null);
      this.$store.dispatch("setCompany", null);
      this.$store.dispatch("setNavStatus", false);
      this.$store.dispatch("set_menu", false);

      this.$router.push("/");
      this.showSnackbar("Sesión cerrada exitosamente", "success");
    },
  },

  watch: {
    "$store.getters.getManifest": {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.manifest = newVal;
        }
      },
    },
    "$store.getters.company": {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.company = newVal;
        }
      },
    },
  },
};
</script>

<style scoped>
.company-profile {
  position: relative;
  background-color: #f5f5f5;
}

.company-banner {
  position: relative;
  overflow: hidden;
}

.banner-pattern {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    30deg,
    rgba(255, 255, 255, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0.1) 75%,
    transparent 75%,
    transparent
  );
  background-size: 10px 10px;
  opacity: 0.1;
}

.company-logo {
  border: 4px solid white;
  background-color: white;
}

.company-details {
  position: relative;
  z-index: 2;
}

.sticky-card {
  position: sticky;
  top: 84px;
}

/* Animaciones */
@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.v-card {
  animation: slideIn 0.5s ease-out;
}

/* Responsive */
@media (max-width: 960px) {
  .sticky-card {
    position: static;
  }

  .company-banner {
    height: auto !important;
  }
}
</style>

<style scoped>
.modern-footer {
  position: relative;
  overflow: hidden;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.stat-card {
  position: relative;
  padding: 24px;
  border-radius: 12px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
}

.stat-content {
  position: relative;
  z-index: 2;
  text-align: center;
}

.stat-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.1), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.stat-card:hover .stat-background {
  opacity: 1;
}

.stat-icon {
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.9);
}

.stat-number {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-bottom: 4px;
}

.stat-label {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.newsletter-section {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 24px;
  backdrop-filter: blur(10px);
}

.newsletter-input {
  background: rgba(255, 255, 255, 0.1);
}

.newsletter-input ::v-deep .v-input__slot {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.newsletter-button {
  height: 40px;
  min-width: 120px;
}

.contact-list ::v-deep .v-list-item {
  min-height: 36px;
}

@media (max-width: 960px) {
  .stats-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .newsletter-button {
    margin-top: 16px;
  }
}

.contact-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 24px;
  backdrop-filter: blur(10px);
}

.messaging-section {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 24px;
  backdrop-filter: blur(10px);
}

.v-text-field ::v-deep .v-input__slot,
.v-textarea ::v-deep .v-input__slot {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.contact-link {
  color: inherit;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.contact-link:hover {
  opacity: 0.8;
}
</style>

<style scoped>
.modern-footer {
  position: relative;
  overflow: hidden;
}

.contact-card,
.support-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 24px;
  backdrop-filter: blur(10px);
  height: 100%;
}

.contact-link {
  color: inherit;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.contact-link:hover {
  opacity: 0.8;
}

.support-option {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 16px;
  transition: all 0.3s ease;
}

.support-option:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.support-btn {
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.support-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.4);
}

.stat-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.stat-card:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.stat-icon {
  margin-right: 12px;
}

.stat-content {
  flex: 1;
}

.stat-value {
  font-size: 20px;
  font-weight: bold;
  color: white;
  line-height: 1.2;
}

.stat-label {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}

.save-contact-btn {
  transition: all 0.3s ease;
}

.save-contact-btn:hover {
  transform: translateY(-2px);
}

.opacity-3 {
  opacity: 0.3;
}

@media (max-width: 960px) {
  .support-section {
    margin-top: 24px;
  }

  .stat-card {
    margin-bottom: 16px;
  }
}
</style>

<style scoped>
.v-app-bar--transparent {
  background: transparent !important;
  box-shadow: none;
}

.hero-section {
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}

/* Agregar transiciones suaves */
.v-card {
  transition: transform 0.3s ease;
}

.v-card:hover {
  transform: translateY(-4px);
}
</style>

<style scoped>
.header-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  z-index: 0;
}

.footer-background {
  position: absolute;
  inset: 0;
  background: radial-gradient(
      circle at 0% 0%,
      rgba(255, 255, 255, 0.1) 0%,
      transparent 50%
    ),
    radial-gradient(circle at 100% 100%, rgba(255, 255, 255, 0.1) 0%, transparent 50%);
  opacity: 0.8;
  z-index: 0;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.v-toolbar__content,
.footer-content {
  position: relative;
  z-index: 1;
}
</style>
