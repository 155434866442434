<template>
  <div class="save-contact-wrapper">
    <v-btn
      color="secondary"
      class="save-contact-btn"
      elevation="2"
      @click="handleSaveContact"
      :loading="loading"
    >
      <v-icon left>mdi-card-account-details</v-icon>
      Guardar en Contactos
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SaveContactButton",
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async handleSaveContact() {
      this.loading = true;

      try {
        // Intenta primero con Contact Picker API (más moderno)
        if ("contacts" in navigator && "ContactsManager" in window) {
          await this.saveUsingContactsAPI();
        } else {
          // Fallback a vCard
          this.saveUsingVCard();
        }
      } catch (error) {
        console.error("Error saving contact:", error);
        this.showError();
      } finally {
        this.loading = false;
      }
    },

    async saveUsingContactsAPI() {
      const properties = ["name", "email", "tel", "address"];
      const contact = {
        name: [this.company.name],
        email: [this.company.email],
        tel: [this.company.mobile],
        address: [
          {
            streetAddress: this.company.address,
            city: this.company.city,
            country: "Colombia",
          },
        ],
      };

      await navigator.contacts.select(properties);
      // La API maneja la inserción del contacto
      this.$emit("contact-saved", "Contacto guardado exitosamente");
    },

    saveUsingVCard() {
      // Crear contenido vCard
      const vCard = `BEGIN:VCARD
  VERSION:3.0
  FN:${this.company.name}
  ORG:${this.company.name}
  EMAIL:${this.company.email}
  TEL:${this.company.mobile}
  ADR:;;${this.company.address};${this.company.city};;;Colombia
  URL:${window.location.origin}
  NOTE:${this.company.description || ""}
  END:VCARD`;

      // Crear y descargar el archivo
      const blob = new Blob([vCard], { type: "text/vcard" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${this.company.name}.vcf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      this.$emit("contact-saved", "Archivo de contacto descargado");
    },

    showError() {
      this.$emit(
        "contact-error",
        "No se pudo guardar el contacto. Por favor, inténtalo de nuevo."
      );
    },
  },
};
</script>

<style scoped>
.save-contact-wrapper {
  margin: 16px 0;
}

.save-contact-btn {
  transition: all 0.3s ease;
  text-transform: none;
  font-weight: 500;
}

.save-contact-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .save-contact-btn {
    width: 100%;
  }
}
</style>
