<template>
  <v-app id="inspire">
    <v-navigation-drawer
      dark
      color="primary"
      v-if="
        $store.state.profile.user_access == 'Admin' ||
        $store.state.profile.user_access == 'Sysop'
      "
      class="navigation-drawer"
      :value="$store.getters.getMenuStatus && $store.state.profile.user_access"
      @input="(val) => (drawer = val)"
      :width="bestWidth > 600 ? 300 : bestWidth"
      app
    >
      <v-list-item>
        <v-avatar rounded>
          <v-img icon contain :src="$store.getters.getManifest.logo" height="35"></v-img>
        </v-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ $store.getters.getManifest.name }}</v-list-item-title>
          <v-list-item-subtitle>{{
            $store.state.profile.user_access
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon @click="setdrawer">mdi-arrow-collapse-left</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-divider></v-divider>
      <v-treeview
        return-object
        item-key="id"
        hoverable
        activatable
        :open="open"
        open-on-click
        selected-color="red"
        @update:active="updateForm"
        @update:open="updateOpen"
        color="warning"
        :items="links"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="!item.icon">
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
          <v-icon :color="item.color" v-else>
            {{ item.icon }}
          </v-icon>
        </template>
      </v-treeview>

      <v-divider></v-divider>

      <template v-slot:append>
        <v-list-item>
          <v-avatar rounded>
            <v-icon color="green" large>mdi-information-outline</v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $store.state.company.name }}</v-list-item-title>
            <v-list-item-subtitle>{{
              $store.state.company.group_name
            }}</v-list-item-subtitle>
            {{ $store.state.company.address }}
          </v-list-item-content>
          <v-list-item-action @click="call_contact(item.mobile)">
            <v-icon>mdi-phone-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <!-- <button @click="install">install</button> -->
    <v-app-bar flat color="white" elevation="0" :value="$store.getters.getNavStatus" app>
      <v-app-bar-nav-icon
        v-if="$store.state.profile.user_access !== 'Sysop'"
        to="Welcome"
      ></v-app-bar-nav-icon>
      <v-app-bar-nav-icon @click="setdrawer" v-else></v-app-bar-nav-icon>

      <v-toolbar-title>{{ $store.getters.company.name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu
        transition="slide-x-transition"
        bottom
        right
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="profile-btn">
            <v-avatar>
              <img
                v-if="$store.getters.profile.Picture"
                :src="$store.getters.profile.Picture"
                alt="Profile"
              />
              <v-icon v-else>mdi-account-circle-outline</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-card class="profile-menu" min-width="300">
          <v-list>
            <v-list-item to="Company">
              <v-list-item-avatar>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $store.getters.company.name }}</v-list-item-title>
                <v-list-item-subtitle>Configuración de la empresa</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  $store.getters.profile.user_name
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ $store.getters.profile.user_email }} /
                  {{ $store.getters.profile.user_access }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="selectPrinter">
              <v-list-item-avatar>
                <v-icon>mdi-printer</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>BT Printer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="logout" class="logout-item">
              <v-list-item-avatar>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Salir</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>

    <AddToHomeScreen />

    <v-snackbar
      v-model="snackToast.dialog"
      :timeout="snackToast.timeout_error"
      absolute
      centered
      vertical
      outlined
      max-width="400"
      :color="snackToast.color"
      elevation="24"
      class="professional-snackbar"
      content-class="text-center"
    >
      <template v-if="snackToast.loading">
        <v-progress-circular
          indeterminate
          size="24"
          color="success"
          class="mr-2"
        ></v-progress-circular>
        {{ snackToast.text }}
      </template>
      <template v-else>
        <v-progress-circular
          indeterminate
          size="50"
          color="success"
          class="mr-2"
        ></v-progress-circular>
        <h3>{{ snackToast.text }}</h3>
      </template>
      <template v-slot:action="{ attrs }" v-if="snackToast.timeout_error == -1">
        <v-btn
          text
          color="red"
          class="mr-3"
          v-bind="attrs"
          @click="snackToast.dialog = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-scale-transition>
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
        class="fabbtn"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>

    <v-dialog :value="$store.getters.getLoading" hide-overlay persistent max-width="480">
      <v-card color="primary" dark>
        <v-card-text>
          Trabajando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <WelcomeSplash :open="splashDialog" @close="splashDialog = !splashDialog" />

    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
      Una actualización disponible.
      <v-btn text @click="refreshApp"> ACTUALIZAR </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import update from "./mixins/update";
// import { webserver } from "./services/webserver.js";
import menu_options from "./utils/menu.js";
import createItem from "./utils/createUser.js";
import AddToHomeScreen from "./components/PwaInstall.vue";
import WelcomeSplash from "./views/Splash.vue";
import createProfile from "./utils/createProfile.js";
import bluetoothPrinter from "./services/bluetoothPrinter";

// const TIME_IN_BOUNDARY = 8;
// const TIME_OUT_BOUNDARY = 20;
// const INTERVAL = 5 * 60 * 1000;

export default {
  components: { AddToHomeScreen, WelcomeSplash },
  beforeMount() {
    // this.$store.dispatch("setHost", window.location.host);
    this.$store.dispatch("setHost", "ai-pos.co");
    // this.$store.dispatch("setHost", "universal.ai-pos.co");

    // var getProfile = window.localStorage.getItem("sc_c");
    // if (getProfile) {
    //   var profile = JSON.parse(
    //     this.CryptoJS.AES.decrypt(getProfile, process.env.VUE_APP_QR4).toString(
    //       this.CryptoJS.enc.Utf8
    //     )
    //   );

    //   this.profile = profile;
    //   window.token = profile.token;
    //   var getStore = window.localStorage.getItem("sc_s");
    //   var store = JSON.parse(
    //     this.CryptoJS.AES.decrypt(getStore, process.env.VUE_APP_QR4).toString(
    //       this.CryptoJS.enc.Utf8
    //     )
    //   );
    //   window.company = store;
    //   this.$store.dispatch("setProfile", profile);
    //   this.$store.dispatch("set_menuItems", this.menu[profile.user_access]);
    //   this.$store.dispatch("setCompany", store);
    //   this.$store.dispatch("setNavStatus", true);
    //   this.$store.dispatch("set_menu", true);
    //   //console.log(window.company);
    //   if (profile.user_access === "CarDealer") {
    //     this.$router.push("/Vehiculos");
    //   } else {
    //     this.$router.push("/Products");
    //   }
    //   // this.$router.push("/Catalogue");
    // } else {
    //   this.$store.dispatch("setNavStatus", false);
    //   this.$store.dispatch("set_menu", false);
    //   this.$router.push("/");
    // }
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.weblogo = "./sites/" + root + "/logo.webp";
    //console.log(this.weblogo);
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
  },
  mounted() {
    //this.get_company();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.$vuetify.goTo(0);
    this.onResize();
  },
  data: () => ({
    bestWidth: window.innerWidth,
    deferredPrompt: null,
    weblogo: null,
    manifest: {},
    splashDialog: true,
    updateExists: false,
    company: null,
    dialog: true,
    profile: { name: null },
    drawer: true,
    isXs: false,
    item: createItem(),
    navStatus: true,
    open: [],
    menu_options: menu_options(),
    links: [],
    menu: [],
    tracker: null,
    fab: null,
    mini: true,
    valid: false,
    reg_dialog: false,
    f_email_val: [
      (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
    ],
    f_required: [(v) => !!v || "Requerido"],
    snackToast: {
      timeout_error: 2000,
      dialog: false,
      text: "",
      color: "success",
    },
    loading_status: false,
  }),
  mixins: [update],
  computed: {},

  beforeDestroy() {
    //clearInterval(this.tracker);
  },
  methods: {
    selectPrinter() {
      bluetoothPrinter.selectPrinter();
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
    async install() {
      this.deferredPrompt.prompt();
    },

    setdrawer() {
      this.$store.dispatch("set_menu", !this.$store.getters.getMenuStatus);
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
      this.bestWidth = window.innerWidth;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.scrollY || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },

    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    updateForm(item) {
      //console.log(item);
      if (item[0].to)
        this.$router.push({
          name: item[0].to,
          params: item[0].params,
          query: item[0].query,
        });
    },
    updateOpen(openItems) {
      //.log("updateOpen", openItems);
      this.open = openItems.length ? [openItems[openItems.length - 1]] : [];
    },
    logout() {
      localStorage.removeItem("sc_c");
      localStorage.removeItem("sc_s");
      this.$store.dispatch("setProfile", createProfile());
      this.$router.push("/Login");
    },
  },
  watch: {
    "$store.getters.getManifest"(newVal) {
      if (newVal) {
        this.manifest = newVal;
      }
    },
    "$store.getters.getToast"(newVal) {
      if (newVal) {
        this.snackToast = newVal;
      }
    },
    "$store.getters.getMenuStatus"(newVal) {
      if (newVal) {
        this.drawer = newVal;
      }
    },
    "$store.getters.profile"(newVal) {
      if (newVal) {
        const groupName = this.$store.getters.company.group_name;
        this.menu = this.menu_options[groupName];
        console.log(this.menu);
        this.links = this.menu[newVal.user_access];

        console.log(newVal.user_access, this.links);
        this.$store.dispatch("set_menuItems", this.menu[newVal.user_access]);
      }
    },
    "$store.getters.getMenuItems"(newVal) {
      if (newVal) {
        this.links = newVal;
        //this.track();
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Material Design Icons";
  src: url("~@mdi/font/fonts/materialdesignicons-webfont.woff2") format("woff2");
}
/* disable rotation and hide original icon */

.fabbtn {
  bottom: 80px !important;
}

.professional-snackbar {
  z-index: 9999;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
}
</style>
