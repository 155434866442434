// services/api.js
import axios from "axios";

// Crea una instancia de Axios con una URL base
const apiClient = axios.create({
  baseURL: "https://my.ai-pos.co/ws/yapp", // Reemplaza con la URL base de tu API
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiClient;
