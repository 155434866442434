<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="400"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text color="primary">
        <v-icon left>mdi-podcast</v-icon>
        Escuchar
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="primary white--text">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="ml-2">Mi Podcast</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="toggleSettings">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </v-card-title>

      <!-- <v-alert v-if="error" type="error" dense dismissible class="ma-0">
        {{ error }}
      </v-alert> -->

      <!-- <v-progress-linear
        :active="isLoading"
        :indeterminate="isLoading"
        absolute
        top
      ></v-progress-linear> -->

      <v-card-text>
        <v-text-field
          v-model="searchQuery"
          prepend-inner-icon="mdi-magnify"
          label="Buscar episodios"
          dense
          outlined
          clearable
          hide-details
          class="ma-4"
        ></v-text-field>

        <v-list>
          <v-list-item
            v-for="(post, index) in filteredPosts"
            :key="post.id"
            @click="selectEpisode(index)"
            :class="{ 'primary--text': currentSlideIndex === index }"
          >
            <v-list-item-avatar tile width="80">
              <v-img :src="getImageUrl(post)">
                <v-row class="fill-height" align="center" justify="center">
                  <v-icon :color="currentSlideIndex === index ? 'primary' : ''">
                    {{
                      currentSlideIndex === index && isPlaying ? "mdi-pause" : "mdi-play"
                    }}
                  </v-icon>
                </v-row>
              </v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ post.title }}</v-list-item-title>
              <v-list-item-subtitle>{{
                formatDate(post.createdAt)
              }}</v-list-item-subtitle>
              <v-chip x-small outlined class="mt-1">{{ post.category }}</v-chip>
            </v-list-item-content>

            <v-list-item-action v-if="currentSlideIndex === index">
              <v-progress-circular
                v-if="isPlaying"
                size="24"
                width="2"
                color="primary"
                indeterminate
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions v-if="currentPost" class="pa-0">
        <!-- <div class="d-flex align-center pa-2">
          
        </div> -->

        <!-- <v-slider
            v-model="audioProgress"
            class="ma-0 pa-0"
            hide-details
            @change="seekTo"
          ></v-slider> -->

        <!-- <div class="d-flex justify-space-between px-2">
            <span class="caption">{{ formatTime(currentTime) }}</span>
            <span class="caption">{{ formatTime(duration) }}</span>
          </div> -->
        <!-- <v-row>
          <v-col cols="12" class="pa-2">
            <div class="subtitle-2 truncate-text">{{ currentPost.title }}</div>
            <div class="caption">{{ formatDate(currentPost.createdAt) }}</div>
          </v-col>
          <v-col cols="12"> -->
        <v-bottom-navigation dark color="white">
          <v-btn icon @click="previousSlide" :disabled="currentSlideIndex === 0">
            <v-icon>mdi-skip-previous</v-icon>
          </v-btn>

          <v-btn icon large class="mx-2" @click="toggleAutoplay">
            <v-icon size="36" :color="isPlaying ? 'primary' : ''">
              {{ isPlaying ? "mdi-pause-circle" : "mdi-play-circle" }}
            </v-icon>
          </v-btn>

          <v-btn
            icon
            @click="nextSlide"
            :disabled="currentSlideIndex === filteredPosts.length - 1"
          >
            <v-icon>mdi-skip-next</v-icon>
          </v-btn>
        </v-bottom-navigation>
        <!-- </v-col>
        </v-row> -->
      </v-card-actions>

      <v-dialog v-model="showSettings" width="300">
        <v-card>
          <v-card-title>Configuración</v-card-title>
          <v-card-text>
            <v-select
              v-model="selectedVoice"
              :items="speechVoices"
              item-text="name"
              return-object
              label="Voz"
              dense
            ></v-select>

            <v-slider
              v-model="speechRate"
              label="Velocidad"
              min="0.5"
              max="2"
              step="0.1"
              thumb-label
            ></v-slider>

            <v-slider
              v-model="volume"
              label="Volumen"
              min="0"
              max="1"
              step="0.1"
              thumb-label
            ></v-slider>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import apiClient from "@/services/api.js";

export default {
  name: "PostPodcastPlayer",

  props: {
    accountId: {
      type: String,
      required: false,
      default: "308581",
    },
  },

  data: () => ({
    dialog: false,
    showSettings: false,
    posts: [],
    searchQuery: "",
    currentSlideIndex: -1,
    isPlaying: false,
    isLoading: false,
    speechVoices: [],
    selectedVoice: null,
    speechRate: 1,
    volume: 1,
    audioProgress: 0,
    audioStartTime: 0,
    audioDuration: 0,
    currentUtterance: null,
    baseUrl: process.env.VUE_APP_API_URL || "https://ai-pos.co",
    error: null,
    currentTime: 0,
    duration: 0,
    progressRAF: null,
  }),

  computed: {
    filteredPosts() {
      if (!this.searchQuery) return this.posts;
      const query = this.searchQuery.toLowerCase();
      return this.posts.filter(
        (post) =>
          post.title.toLowerCase().includes(query) ||
          post.category.toLowerCase().includes(query)
      );
    },

    currentPost() {
      return this.filteredPosts[this.currentSlideIndex] || null;
    },
  },

  watch: {
    dialog(val) {
      if (val && this.posts.length === 0) {
        this.init();
      }
    },
  },

  created() {
    this.memoizedFilterPosts = this.memoizeFunction(this.filterPosts);
  },

  beforeDestroy() {
    this.cleanup();
  },

  mounted() {
    this.initVoices();
  },

  methods: {
    memoizeFunction(fn) {
      const cache = new Map();
      return (...args) => {
        const key = JSON.stringify(args);
        if (cache.has(key)) return cache.get(key);
        const result = fn.apply(this, args);
        cache.set(key, result);
        return result;
      };
    },

    async seekTo(percentage) {
      if (!this.currentUtterance || !this.currentPost) return;

      const fullText = this.sanitizeText(this.currentPost.content);
      const charPosition = Math.floor((fullText.length * percentage) / 100);
      const remainingText = fullText.slice(charPosition);

      window.speechSynthesis.cancel();
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Mantener duración total y ajustar tiempo actual
      this.currentTime = this.duration * (percentage / 100);
      this.audioStartTime = Date.now() - this.currentTime;

      const utterance = new SpeechSynthesisUtterance(remainingText);
      utterance.voice = this.selectedVoice?._voice;
      utterance.volume = this.volume;
      utterance.rate = this.speechRate;

      this.currentUtterance = utterance;
      window.speechSynthesis.speak(utterance);
      this.isPlaying = true;
      this.updateProgress();
    },
    formatTime(ms) {
      const seconds = Math.floor(ms / 1000);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${String(remainingSeconds).padStart(2, "0")}`;
    },

    filterPosts(posts, query) {
      if (!query) return posts;
      query = query.toLowerCase();
      return posts.filter(
        (post) =>
          post.title.toLowerCase().includes(query) ||
          post.category.toLowerCase().includes(query)
      );
    },

    cleanup() {
      this.stopPresentation();
      if (this.progressRAF) {
        cancelAnimationFrame(this.progressRAF);
      }
    },

    sanitizeText(text) {
      return text
        .replace(/<[^>]*>/g, "") // Remove HTML tags
        .replace(/&nbsp;/g, " ") // Replace &nbsp; with space
        .replace(/\s+/g, " ")
        .trim();
    },

    async init() {
      this.isLoading = true;
      try {
        await this.fetchPosts();
      } finally {
        this.isLoading = false;
      }
    },

    async fetchPosts() {
      try {
        const { data } = await apiClient.get("/yapps", {
          params: { account: this.accountId },
        });

        this.posts = data.map((post) => ({
          ...post,
          createdAt: post.created_at ? new Date(post.created_at).toISOString() : null,
          authorAvatar: post.author_avatar || "https://picsum.photos/40",
          author_name: post.author_name || "Autor",
          category: post.category || "General",
        }));
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    },

    selectEpisode(index) {
      if (this.currentSlideIndex === index) {
        this.toggleAutoplay();
      } else {
        this.playEpisode(index);
      }
    },

    async playEpisode(index) {
      if (this.isPlaying) {
        await this.stopPresentation();
      }
      this.currentSlideIndex = index;
      this.startPresentation();
    },

    initVoices() {
      const loadVoices = () => {
        const voices = window.speechSynthesis.getVoices();
        this.speechVoices = voices
          .filter((voice) => voice.lang.startsWith("es"))
          .map((voice) => ({
            name: `${voice.name} (${voice.lang})`,
            _voice: voice,
          }));
        if (this.speechVoices.length) {
          this.selectedVoice = this.speechVoices[0];
        }
      };

      if (window.speechSynthesis.onvoiceschanged !== undefined) {
        window.speechSynthesis.onvoiceschanged = loadVoices;
      }
      loadVoices();
    },

    async speakText(text) {
      if (!window.speechSynthesis) {
        this.error = "Tu navegador no soporta la síntesis de voz";
        return;
      }

      return new Promise((resolve, reject) => {
        const utterance = new SpeechSynthesisUtterance(text);
        this.currentUtterance = utterance;

        utterance.voice = this.selectedVoice?._voice;
        utterance.volume = this.volume;
        utterance.rate = this.speechRate;

        utterance.onend = () => {
          this.currentUtterance = null;
          resolve();
        };

        utterance.onerror = (event) => {
          this.error = `Error en la síntesis de voz: ${event.error}`;
          reject(event);
        };

        window.speechSynthesis.speak(utterance);
      });
    },

    async speakCurrentSlide() {
      if (!this.currentPost) return;

      try {
        const cleanContent = this.sanitizeText(this.currentPost.content);
        const textChunks = cleanContent
          .split(/[.!?]+/)
          .filter((chunk) => chunk.trim().length > 0);

        for (const chunk of textChunks) {
          if (!this.isPlaying) break;
          await this.speakText(chunk.trim());

          // Pequeña pausa entre oraciones
          await new Promise((resolve) => setTimeout(resolve, 300));
        }

        if (this.isPlaying && this.currentSlideIndex < this.filteredPosts.length - 1) {
          this.nextSlide();
        }
      } catch (error) {
        console.error("Error speaking text:", error);
        this.stopPresentation();
      }
    },

    updateProgress() {
      if (!this.isPlaying) return;

      this.currentTime = Date.now() - this.audioStartTime;
      this.audioProgress = Math.min((this.currentTime / this.duration) * 100, 100);

      if (this.audioProgress < 100) {
        this.progressRAF = requestAnimationFrame(this.updateProgress);
      }
    },

    async startPresentation() {
      if (this.isPlaying) return;

      this.isPlaying = true;
      this.isLoading = true;

      try {
        await this.speakCurrentSlide();
      } catch (error) {
        console.error("Error in presentation:", error);
        this.stopPresentation();
      } finally {
        this.isLoading = false;
      }
    },

    stopPresentation() {
      if (this.currentUtterance) {
        window.speechSynthesis.cancel();
        this.currentUtterance = null;
      }
      this.isPlaying = false;
      this.isLoading = false;
      this.audioProgress = 0;
    },

    toggleAutoplay() {
      if (this.isPlaying) {
        this.stopPresentation();
      } else {
        this.startPresentation();
      }
    },

    previousSlide() {
      if (this.currentSlideIndex > 0) {
        this.stopPresentation();
        this.currentSlideIndex--;
        this.startPresentation();
      }
    },

    nextSlide() {
      if (this.currentSlideIndex < this.filteredPosts.length - 1) {
        this.stopPresentation();
        this.currentSlideIndex++;
        this.startPresentation();
      }
    },

    formatDate(date) {
      if (!date) return "";
      return new Date(date).toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },

    getImageUrl(post) {
      if (!post?.image_url) return "https://picsum.photos/600/300";
      return `${this.baseUrl}/yapps_img/${post.account}/${post.category_id}/${post.image_url}`;
    },

    toggleSettings() {
      this.showSettings = !this.showSettings;
    },

    closeDialog() {
      this.dialog = false;
      this.stopPresentation();
      this.currentSlideIndex = -1;
      this.searchQuery = "";
      this.showSettings = false;
    },
  },
};
</script>

<style scoped>
.content-container {
  height: calc(100vh - 180px);
  overflow-y: auto;
}

.player-controls {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 5;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: rgba(0, 0, 0, 0.1);
}

.progress-fill {
  height: 100%;
  background: var(--v-primary-base);
  transition: width 0.1s linear;
}

@media (min-width: 600px) {
  .content-container {
    height: calc(100vh - 220px);
  }
}
</style>
